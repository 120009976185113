import * as payment from "../services/razorpay";
import { toast } from "react-toastify";
import CryptoJS from "crypto-js";
import jwtEncode from "jwt-encode";

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
const TOKEN_SECRET = process.env.REACT_APP_TOKEN_SECRET;

export const doPayment = async (data) => {
  switch (data.members.transaction_status) {
    case "PROCESSING":
      toast.error("Your old payment is still processing...", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      break;
    case "COMPLETED":
      toast.error("Your payment already completed", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      break;
    default:
      await payment.makePayment(data);
  }
};

// Encrypt data
export const encryptData = (data) => {
  const ciphertext = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    SECRET_KEY
  ).toString();
  return encodeURIComponent(ciphertext); // Encode for URL safety
};

// Generate JWT Token (5 min expiry)
export const generateToken = (secreteToken) => {
  const payload = {
    ...secreteToken,
    exp: Math.floor(Date.now() / 1000) + 60 * 5, // Expires in 5 minutes
  };

  return jwtEncode(payload, TOKEN_SECRET);
};

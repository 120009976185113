import React, { useEffect, useState } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Button, Card, Col, Modal, Row, Table } from "react-bootstrap";
import {
  getAssociationById,
  getAssociationUpdate,
  listAssociationMembers,
  exitAssociation,
} from "../../../redux/actions/association.action";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "../../components/InfiniteScroll/InfiniteScroll";
import { useNavigate, useParams, Link } from "react-router-dom";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import { SET_LOADING } from "../../../redux/constants/globalActionTypes";
import { RESET_ASSOCIATION } from "../../../redux/constants/assocGroupActionTypes";
import logo from "../../../img/logo.svg";

import ConfirmationPopup from "../../components/Popup/ConfirmationPopup";

const AssociationMembers = () => {
  const { associationId } = useParams();
  const [isAdmin, setIsAdmin] = useState(false);

  let navigate = useNavigate();
  const dispatch = useDispatch();
  const associationMembers = useSelector(
    (state) => state.association.associationMembers
  );
  // const [pageNo, setPageNo] = useState(1);
  const pageNo = useSelector((state) => state.association.subPageNumber);
  const hasMore = useSelector((state) => state.association.subHasMore);

  const associationById = useSelector(
    (state) => state.association.associationById
  );

  const [showPopup, setShowPopup] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const headerColumns = [
    {
      name: "Name",
      key: "fullName",
    },
    {
      name: "Speciality",
      key: "speciality",
    },
    {
      name: "Designation",
      key: "designation",
    },
    {
      name: "College/Institute",
      key: "institution",
    },
    {
      name: "Location",
      key: "location",
    },
  ];

  useEffect(() => {
    dispatch({ type: SET_LOADING });
    dispatch(
      listAssociationMembers({ page: pageNo, associationId: associationId })
    );
  }, []);

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_ASSOCIATION });
    };
  }, []);

  // useEffect(() => {
  //   dispatch(getAssociationById({ associationId: associationId })).then(
  //     (res) => {
  //       if (res.createdBy === currentUser) {
  //         setIsAdmin(true);
  //       } else {
  //         setIsAdmin(false);
  //       }
  //     }
  //   );
  // }, []);

  const fetchAssociation = () => {
    dispatch(
      listAssociationMembers({ page: pageNo, associationId: associationId })
    );
  };

  // const loadMoreData = async () => {
  //   setPageNo((prevPageNo) => prevPageNo + 1);
  //   console.log(pageNo)
  // }

  const [currentUser, setCurrentUser] = useState(
    localStorage.getItem("userId")
  );

  const deleteMember = (id) => {
    if (id == null) {
      return;
    }

    dispatch(
      exitAssociation({ associationId: associationId, userId: id }, navigate)
    );
  };

  return (
    <Root>
      <Breadcrumb
        title={"Associations and Groups"}
        items={[
          {
            name: "Association Dashboard",
            routeTo: `/AssociationDetails/${associationId}`,
          },
          {
            name: "Association Members",
          },
        ]}
      />
      <ContainerWrapper>
        <Row className="mt-2">
          <Col className="d-lg-flex justify-content-lg-between">
            <div>
              <h4 className="mb-1">Association Members</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            {associationMembers[0]?.members?.length > 0 ? (
              <Card className="bg-white card rounded-3 mb-4">
                <Card.Body className="card-body px-4 py-3">
                  <div className="table-responsive">
                    <Table className="table table-borderless table-striped table-lg align-middle cme-table pb-0">
                      <thead className="">
                        {headerColumns.map((val, index) => (
                          <th className="" key={val.key}>
                            {val.name}
                          </th>
                        ))}
                        <th className="text-end">&nbsp;</th>
                      </thead>
                      <tbody>
                        {/* {associationMembers.map((val, index) => (
                          <tr key={index}>
                            {headerColumns.map((headerValue, headerIdex) => (
                              <td>
                                <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                  {headerValue.name}:
                                </p>
                                
                                  <p className="mb-0"> {val[headerValue.key]}</p>
                              </td>
                            ))}
                          </tr>
                        ))} */}

                        {associationMembers[0]?.members?.map((val, index) => (
                          <tr key={index}>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                {val?.userData?.fullName}:
                              </p>
                              <Link to={`/surgeon-profile/${val?.userId}`}>
                                {val?.isAdmin ? (
                                  <p className="mb-0">
                                    {`${val?.userData?.fullName}   `}
                                    <span className="badge badge-success">
                                      Admin
                                    </span>
                                  </p>
                                ) : (
                                  <p className="mb-0">{`${val?.userData?.fullName}`}</p>
                                )}
                              </Link>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                {val?.userData?.speciality}:
                              </p>
                              <p className="mb-0">
                                {" "}
                                {val?.userData?.speciality}
                              </p>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                {val?.userData?.designation}:
                              </p>
                              <p className="mb-0">
                                {" "}
                                {val?.userData?.designation}
                              </p>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                {val?.userData?.institution}:
                              </p>
                              <p className="mb-0">
                                {" "}
                                {val?.userData?.institution}
                              </p>
                            </td>
                            <td>
                              <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                {val?.userData?.city}:
                              </p>
                              <p className="mb-0">
                                {" "}
                                {`${
                                  val?.userData?.city
                                    ? val?.userData?.city + ","
                                    : ""
                                } ${
                                  val?.userData?.city
                                    ? val?.userData?.city + ","
                                    : ""
                                } ${
                                  val?.userData?.country
                                    ? val?.userData?.country + ","
                                    : ""
                                }`}
                              </p>
                            </td>
                            {!val?.isAdmin &&
                            associationMembers[0]?.createdBy == currentUser ? (
                              <td>
                                <button
                                  className=" btn btn-info mb-2 btn-sm me-1"
                                  onClick={() => {
                                    setDeleteId(val.userId);
                                    setShowPopup(true);
                                  }}
                                >
                                  <span style={{ color: "red" }}>
                                    <i className="fa-solid fa-trash me-1"></i>
                                  </span>
                                </button>
                              </td>
                            ) : null}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Card.Body>
              </Card>
            ) : (
              <NoDataFound />
            )}
          </Col>
        </Row>
        <ConfirmationPopup
          show={showPopup}
          message="Are you sure you want to delete this member?"
          header="Confirm Delete ?"
          onConfirm={() => {
            deleteMember(deleteId);
            setShowPopup(false);
          }}
          onCancel={() => {
            setShowPopup(false);
          }}
        />
        {/* <Button
          className=" btn btn-info mb-2 btn-sm me-1"
          style={{width:"100%"}}
          onClick={loadMoreData}
        >
          Load More Data{" "}
          <i className="fa-solid fa-arrow-right"></i>
        </Button> */}
        <InfiniteScroll
          fetchFunction={fetchAssociation}
          globalState={associationMembers}
          hasMore={hasMore}
        />
      </ContainerWrapper>
    </Root>
  );
};

export default AssociationMembers;

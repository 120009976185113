import React, { useEffect, useRef, useState } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { Button, Card, Col, Row } from "react-bootstrap";
import ThumbnailCard from "../../components/Cards/ThumbnailCard";
import Filters from "../../components/Filters/Filters";
import { useDispatch, useSelector } from "react-redux";

import Modal from "react-bootstrap/Modal";

import { getAllMeetings } from "../../../redux/actions/meeting.action";
import {
  getLiveEvents,
  registerLiveEvents,
} from "../../../redux/actions/conference.action";
import { useNavigate, Link } from "react-router-dom";
import NoDataFound from "../../components/NoDataFound/NoDataFound";
import InfiniteScroll from "../../components/InfiniteScroll/InfiniteScroll";
import {
  formatDate,
  formattTime,
  timeAgo,
  getStatusFromDate,
  isMeetingEnd,
} from "../../../utils/core";
import ProfileComplete from "../../components/CompleteProfile/ProfileComplete";

import {
  CLEAR_LOADING,
  SET_LOADING,
} from "../../../redux/constants/globalActionTypes";

import { encryptData, generateToken } from "../../../utils/payment"; // Import routes configuration

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

// import Brochure from "../../../img/live_events/HSICON-2024-SCHEDULE.pdf";

import * as payment from "../../../services/razorpay";

const LiveConference = () => {
  let navigate = useNavigate();
  const isInitialMount = useRef(true);

  const dispatch = useDispatch();
  const meetingsList = useSelector((state) => state.meeting.meetingsList);
  const pageNo = useSelector((state) => state.meeting.page);
  const hasMore = useSelector((state) => state.meeting.hasMore);
  const [isLoading, setIsLoading] = useState(false);

  const liveConferenceList = useSelector(
    (state) => state.conference.liveConference
  );

  const [currentUser, setCurrentUser] = useState(
    localStorage.getItem("userId")
  );

  const [isPayementCheck, setIsPayementCheck] = useState(true);
  const [cancelData, setCancelData] = useState(null);

  const [iconChanged, setIconChanged] = useState(false);
  const [iconBChanged, setIconBChanged] = useState(false);

  const [show, setShow] = useState(false);
  const [modelDetails, setModelDetails] = useState(null);

  const handleClose = () => {
    setModelDetails(null);
    setShow(false);
  };

  const handleShow = (data) => {
    setModelDetails(data);
    setShow(true);
  };

  const validationSchema = Yup.object({
    certificate_name: Yup.string().required("Full Name is required"),
  });

  var intervalId;

  useEffect(() => {
    dispatch({ type: SET_LOADING });
    const getConference = async () => {
      dispatch(getLiveEvents({ page: 1 }, true));
    };

    getConference();
  }, []);

  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     console.log("cancelData", cancelData);

  //     if (cancelData) {
  //       payment.closeWindow(cancelData);

  //       event.preventDefault();
  //       event.returnValue = ""; // For legacy browsers
  //     }
  //   };

  //   // Add event listener for the beforeunload event
  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   // Cleanup the event listener on component unmount
  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, [cancelData]);

  // const registerNow = async (id) => {
  //   dispatch(registerLiveEvents({ id: id })).then(async (response) => {
  //     if (response == "") {
  //       window.location.reload();
  //     } else {
  //       response.type = "LIVE_EVENTS";
  //       if (response.members.transaction_status === "INIT") {
  //         setCancelData(response);
  //         await payment.makePayment(response);
  //       }
  //     }
  //   });
  // };

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    setIsLoading(true);
    const payload = {
      certificate_name: values.certificate_name,
      registration_number: values.registration_number,
      amount: values?.amount,
      id: values.id,
    };

    dispatch(registerLiveEvents(payload)).then(async (response) => {
      if (response == "") {
        window.location.reload();
      } else {
        response.type = "LIVE_EVENTS";
        if (response.members.transaction_status === "INIT") {
          setCancelData(response);
          // const secreteToken = {
          //   data: JSON.parse(localStorage.getItem("token")),
          // };

          // const encryptedData = await encryptData(response);
          // const token = await generateToken(secreteToken);
          // window.location.href = `http://localhost:3001?info=${encryptedData}&id=${token}`;
          await payment.makePayment(response);
        }
      }
    });

    // dispatch(submitFeedback(formData, navigate)).then((res) => {
    //   if (res === "success") {
    //     resetForm();
    //   }
    // });
    setIsLoading(false);
    setSubmitting(false);
  };

  const handleOpenPdf = (url) => {
    // PDF file located in the public folder
    const pdfUrl = `https://d27m4xh8yh8co7.cloudfront.net/${process.env.REACT_APP_STAGE}${url}`;
    window.open(pdfUrl, "_self"); // Opens the PDF in a new tab
  };

  const handleCopy = (id) => {
    const textToCopy = document.getElementById("zoom-link" + id).innerText;

    // Copy text to clipboard
    navigator.clipboard.writeText(textToCopy).then(() => {
      // Change icon after copy
      if (id === "A") {
        setIconChanged(true);
      }
      if (id == "B") {
        setIconBChanged(true);
      }
      // Optional: revert the icon after a delay
      setTimeout(() => {
        setIconChanged(false);
        setIconBChanged(false);
      }, 5000); // revert back after 5 seconds
    });
  };

  // const onChangeDesignation = (value, setFieldValue) => {
  //   setFees(value);
  //   setFieldValue("designation", value);
  // };

  const styles = {
    radioGroup: {
      display: "flex",
      flexDirection: "column",
      gap: "1.5rem", // Space between each radio button group
    },
    radioItem: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start", // Align items to the left
      gap: "2rem", // Space between the radio button and label
    },
    formLabel: {
      margin: 0,
      fontSize: "1rem", // Adjust size as needed
    },
  };

  const watchConference = (url, title) => {
    window.open(url, "_blank");
  };

  return (
    <Root>
      <Breadcrumb
        title={"Live Conference"}
        fieldOne="Dashboard"
        fieldTwo="Live Conference"
      />
      <ContainerWrapper>
        {/* <ProfileComplete /> */}
        <div className="row mt-2">
          <div className="col-12 d-lg-flex justify-content-lg-between">
            <div>
              <h2 className="h4 mb-1">Live Conference</h2>
              <p>Total {liveConferenceList.length} Conference</p>
            </div>
            {/* <div className=" d-lg-block">
              <button
                title="Recording of Conference"
                className="btn btn-primary mt-2"
                onClick={() => {
                  navigate("/recorded-conference-workshop");
                }}
              >
                Recording of Conference{" "}
                <i className="fa-solid fa-arrow-right"></i>
              </button>
            </div> */}
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="bg-white card rounded-3 mb-4">
              {liveConferenceList.length > 0 ? (
                <div className="card-body px-4 py-3">
                  <div className="table-responsive">
                    <table className="table table-borderless table-striped table-lg align-middle cme-table pb-0">
                      <thead className="">
                        <th>Topic</th>
                        <th>Date &amp; Time</th>
                        <th>Hosted by</th>
                        {/* <th>Attendies</th> */}
                        <th>Fees</th>
                        <th>Status</th>
                        <th className="text-end" style={{ width: "200px" }}>
                          &nbsp;
                        </th>
                      </thead>
                      <tbody>
                        {liveConferenceList.map((val, index) => (
                          <>
                            <tr>
                              <td className="fw-bold">
                                <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                  Topic:
                                </p>
                                <h6 className="mb-0">{val.title}</h6>
                              </td>
                              <td>
                                <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                  Date &amp; Time:
                                </p>
                                <p className="mb-0">
                                  {formatDate(val.startDate)} to{" "}
                                  {formatDate(val.endDate)}{" "}
                                  {formattTime(val.startTime)} to{" "}
                                  {formattTime(val.endTime)}
                                </p>
                              </td>
                              <td>
                                <p className="text-muted mb-0 fw-bold d-block d-lg-none mb-2">
                                  Hosted by:
                                </p>
                                <div className="author d-flex">
                                  <span className="author-details">
                                    <p className="fw-bold mb-0">
                                      {/* Host details here */}
                                    </p>
                                    <p className="text-muted mb-0">{`${val.host}`}</p>
                                  </span>
                                </div>
                              </td>
                              <td>
                                <strong>
                                  {val.fees > 0 ? `Rs. ${val.fees}` : `Free`}
                                </strong>
                              </td>
                              <td>
                                <p className="text-muted mb-0 fw-bold d-block d-lg-none">
                                  Status:
                                </p>
                                {(() => {
                                  const dataResult = getStatusFromDate(
                                    val.startDate,
                                    val.endDate
                                  );

                                  return val.isCancelled ? (
                                    <span className={`badge badge-danger`}>
                                      {`Cancelled`}
                                    </span>
                                  ) : (
                                    <span
                                      className={`badge badge-${dataResult.color}`}
                                    >
                                      {dataResult.status}
                                    </span>
                                  );
                                })()}
                              </td>
                              <td className="text-left text-lg-end">
                                <button
                                  rel="noopener noreferrer"
                                  className="btn btn-primary mb-2 m-2 btn-sm"
                                  onClick={() => handleOpenPdf(val.brochure)}
                                >
                                  Brochure{" "}
                                  <i className="fa-solid fa-arrow-right"></i>
                                </button>
                                {!val.isCancelled ? (
                                  !isMeetingEnd(val.endDate) ? (
                                    val.paymentStatus &&
                                    val.userTransactionStatus ===
                                      "COMPLETED" ? (
                                      val.joinUrl != null ? (
                                        <>
                                          <a
                                            href={val.joinUrl}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="btn btn-primary mb-2 btn-sm"
                                          >
                                            Join Hall-A{" "}
                                            <i className="fa-solid fa-arrow-right"></i>
                                          </a>
                                          <a
                                            href={val.joinUrlTwo}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="btn btn-primary mb-2 btn-sm"
                                          >
                                            Join Hall-B{" "}
                                            <i className="fa-solid fa-arrow-right"></i>
                                          </a>
                                        </>
                                      ) : (
                                        <button
                                          className="btn btn-primary mb-2 btn-sm"
                                          data-toggle="collapse"
                                          href={`#multiCollapseExample-${index}`}
                                          role="button"
                                          aria-expanded="false"
                                          aria-controls={`multiCollapseExample-${index}`}
                                        >
                                          Join{" "}
                                          <i className="fa-solid fa-arrow-right"></i>
                                        </button>
                                      )
                                    ) : !val.paymentStatus ? (
                                      val.userTransactionStatus ===
                                      "PROCESSING" ? (
                                        <span className={`badge badge-warning`}>
                                          Payment Processing...
                                        </span>
                                      ) : (
                                        <button
                                          rel="noopener noreferrer"
                                          className="btn btn-success mb-2 btn-sm"
                                          onClick={() =>
                                            handleShow({
                                              id: val._id,
                                              title: val.title,
                                              amount: val.fees,
                                            })
                                          }
                                        >
                                          Register Now{" "}
                                          <i className="fa-solid fa-arrow-right"></i>
                                        </button>
                                      )
                                    ) : null
                                  ) : null
                                ) : null}
                              </td>
                            </tr>
                            {val?.join && (
                              <tr>
                                <td colSpan="6">
                                  <div
                                    className="collapse multi-collapse"
                                    id={`multiCollapseExample-${index}`}
                                  >
                                    <div className="card card-body">
                                      <div className="row">
                                        {val.join.map((res, idx) => (
                                          <div
                                            className="col-12 col-md-6 col-lg-3"
                                            key={idx}
                                          >
                                            <Card style={{ width: "100%" }}>
                                              <div className="card-thumb-wrap">
                                                <iframe
                                                  width="100%"
                                                  height="170px"
                                                  src={
                                                    `https://d27m4xh8yh8co7.cloudfront.net/${process.env.REACT_APP_STAGE}${res?.brochure}` ||
                                                    ""
                                                  }
                                                  title="Surgicshare Document viewer"
                                                  frameBorder="0"
                                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                  allowFullScreen
                                                ></iframe>
                                              </div>

                                              <Card.Body>
                                                <Card.Title>
                                                  {res?.hall || "No Title"}
                                                </Card.Title>
                                                <Button
                                                  variant="primary"
                                                  onClick={() =>
                                                    handleOpenPdf(
                                                      res?.brochure || "#"
                                                    )
                                                  }
                                                >
                                                  Brochure
                                                </Button>
                                                <Button
                                                  variant="primary"
                                                  className="ms-2"
                                                  onClick={() =>
                                                    watchConference(
                                                      res?.url || "#",
                                                      res?.hall || "Unknown"
                                                    )
                                                  }
                                                >
                                                  Join
                                                  <i className="fa-solid fa-arrow-right"></i>
                                                </Button>
                                              </Card.Body>
                                            </Card>
                                            {/* <a
                                              href={`${res.url}`}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                              className="btn btn-primary mb-2 btn-sm w-100"
                                            >
                                              {`Join ${res.hall}`}{" "}
                                              <i className="fa-solid fa-arrow-right"></i>
                                            </a> */}
                                          </div>
                                        ))}
                                      </div>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            )}
                          </>
                        ))}
                      </tbody>
                    </table>
                    {/* {liveConferenceList ? (
                      
                    ) : null} */}

                    {/* Copy Link Pannel */}
                    <div class="row">
                      <div class="col-12">
                        <div class="collapse multi-collapse" id="hallclink">
                          <div class="card card-body">
                            <div className="row align-items-center">
                              <div className="col-6">
                                <p
                                  id="zoom-text"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Click the button to cpy the link. Paste it in
                                  browser to join{" "}
                                  <span style={{ color: "red" }}>Hall-A</span>
                                </p>
                                <p id="zoom-linkA" style={{ display: "none" }}>
                                  https://us06web.zoom.us/j/82820982530?pwd=v1neqd0fbsR32gG48v46YZTzMUtb0h.1
                                </p>
                              </div>
                              <div className="col-2">
                                <button
                                  onClick={() => {
                                    handleCopy("A");
                                  }}
                                  className="btn btn-success mb-2 btn-sm"
                                >
                                  {iconChanged ? `copied` : `copy`}
                                  <i
                                    className={
                                      iconChanged
                                        ? "fa-solid fa-check"
                                        : "fa-solid fa-copy"
                                    }
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="collapse multi-collapse" id="halldlink">
                          <div class="card card-body">
                            <div className="row align-items-center">
                              <div className="col-6">
                                <p
                                  id="zoom-text"
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Click the button to cpy the link. Paste it in
                                  browser to join{" "}
                                  <span style={{ color: "red" }}>Hall-B</span>
                                </p>
                                <p id="zoom-linkB" style={{ display: "none" }}>
                                  https://us06web.zoom.us/j/88415153895?pwd=MTvUSMOLBdUvFdtVgDqprZ2QCZ25pX.1
                                </p>
                              </div>
                              <div className="col-2">
                                <button
                                  onClick={() => {
                                    handleCopy("B");
                                  }}
                                  className="btn btn-success mb-2 btn-sm"
                                >
                                  {iconBChanged ? `copied` : `copy`}
                                  <i
                                    className={
                                      iconBChanged
                                        ? "fa-solid fa-check"
                                        : "fa-solid fa-copy"
                                    }
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Continue to payment */}
                    <Modal
                      show={show}
                      onHide={handleClose}
                      backdrop="static"
                      keyboard={false}
                    >
                      <Modal.Header>
                        <Modal.Title>{modelDetails?.title}</Modal.Title>
                      </Modal.Header>
                      <Formik
                        initialValues={{
                          certificate_name: "",
                          registration_number: "",
                          amount: modelDetails?.amount,
                          id: modelDetails?.id,
                        }}
                        validationSchema={validationSchema}
                        onSubmit={onSubmit}
                      >
                        {({ isSubmitting, setFieldValue }) => (
                          <>
                            <Form>
                              <Modal.Body>
                                <div className="mb-3">
                                  <label htmlFor="title" className="form-label">
                                    Full Name (for Cerification){" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <Field
                                    type="text"
                                    id="certificate_name"
                                    name="certificate_name"
                                    className="form-control"
                                  />
                                  <ErrorMessage
                                    name="certificate_name"
                                    component="div"
                                    className="text-danger text-small"
                                  />
                                </div>
                                <div className="mb-3">
                                  <label htmlFor="title" className="form-label">
                                    NMC Registration No. (If applicable){" "}
                                    {/* <span className="text-danger">
                                                        *
                                                      </span> */}
                                  </label>
                                  <Field
                                    type="text"
                                    id="registration_number"
                                    name="registration_number"
                                    className="form-control"
                                  />
                                </div>
                              </Modal.Body>
                              <Modal.Footer>
                                <Button
                                  variant="secondary"
                                  onClick={handleClose}
                                >
                                  Cancel
                                </Button>
                                <div className="d-flex justify-content-center">
                                  {" "}
                                  <Button
                                    type="submit"
                                    variant="primary"
                                    disabled={isSubmitting}
                                  >
                                    {/* {isLoading ? (
                                                        <span
                                                          class="spinner-border spinner-border-sm"
                                                          role="status"
                                                          aria-hidden="true"
                                                        ></span>
                                                      ) : null} */}
                                    Continue to Payment
                                  </Button>
                                </div>
                              </Modal.Footer>
                            </Form>
                          </>
                        )}
                      </Formik>
                    </Modal>
                  </div>
                </div>
              ) : (
                <NoDataFound />
              )}
            </div>
          </div>
        </div>
      </ContainerWrapper>
    </Root>
  );
};

export default LiveConference;

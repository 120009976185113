import React, { useEffect, useRef, useState } from "react";
import Root from "../../../components/Root";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../../layouts/ContainerWrapper";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import ThumbnailCard from "../../../components/Cards/ThumbnailCard";
// import Filters from "../../../components/Filters/Filters";
import { useDispatch, useSelector } from "react-redux";

import { getAllVideos } from "../../../../redux/actions/recorded.conference.action";
import { getAssociationEventRecordings } from "../../../../redux/actions/association.action";
import { useNavigate, useParams } from "react-router-dom";
import NoDataFound from "../../../components/NoDataFound/NoDataFound";
import InfiniteScroll from "../../../components/InfiniteScroll/InfiniteScroll";
import { timeAgo } from "../../../../utils/core";
import { RESET_VIDEOS } from "../../../../redux/constants/recordedConferenceActionTypes";
import { SET_LOADING } from "../../../../redux/constants/globalActionTypes";
import Accordion from "react-bootstrap/Accordion";

const BrowseSurgeryVideo = () => {
  let navigate = useNavigate();
  const isInitialMount = useRef(true);

  const dispatch = useDispatch();
  const { associationId } = useParams();
  const videosList = useSelector(
    (state) => state.association.AssociationEventRecordings
  );
  const [conferenceList, setConferenceList] = useState([]);
  const [eventTitle, setEventTitle] = useState(null);
  const pageNo = 1;

  useEffect(() => {}, []);

  useEffect(() => {
    dispatch({ type: SET_LOADING });
    dispatch(
      getAssociationEventRecordings({
        page: pageNo,
        assocId: associationId,
      })
    );
  }, []);

  useEffect(() => {
    let data = videosList[0]?.recordingUrls;
    setEventTitle(videosList[0]?.title);

    if (data) {
      const groupedArray = Object.values(
        data?.reduce((acc, { event_date, brochure, button, url, _id }) => {
          if (!acc[event_date]) {
            acc[event_date] = { event_date, data: [] };
          }
          acc[event_date].data.push({ brochure, button, url, _id });
          return acc;
        }, {})
      );
      setConferenceList(groupedArray);
    }
  }, [videosList]);

  const handleOpenPdf = (pdfUrl) => {
    window.open(pdfUrl, "_blank"); // Opens the PDF in a new tab
  };

  const watchConference = (url, title) => {
    navigate(`/player/recorded-conference?title=${title}&code=${url}`);
  };

  return (
    <Root>
      {/* <Breadcrumb
        title={"Recorded Conference"}
        fieldOne="Dashboard"
        fieldTwo="Browse Recorded Conference"
      /> */}

      <Breadcrumb
        title={"Recorded Live Surgery / Web CME"}
        items={[
          {
            name: "Association Dashboard",
            routeTo: `/AssociationDetails/${associationId}`,
          },
          {
            name: "Browse Recorded Live Surgery / Web CME",
          },
        ]}
      />

      <ContainerWrapper>
        <Row className="mt-2">
          <Col className="d-lg-flex justify-content-lg-between">
            <div>
              <h4 className="mb-1">Browse Recorded Live Surgery / Web CME</h4>
              <p>
                Browse Recorded Live Surgery / Web CME from top surgeons of your
                field.
              </p>
            </div>
          </Col>
        </Row>

        <Row>
          {conferenceList && conferenceList.length > 0 ? (
            <Accordion>
              {conferenceList.map((val, index) => (
                <Accordion.Item eventKey={index} key={index}>
                  <Accordion.Header>
                    <div key={1} className="col-md-2">
                      {eventTitle ? `${eventTitle}` : ""} {"  "}
                    </div>
                    <div key={1} className="col-md-2">
                      {val?.event_date || "No Date"}{" "}
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    {Array.isArray(val?.data) && val.data.length > 0 ? (
                      <div className="row">
                        {val.data.map((row, rowIndex) => (
                          <div key={rowIndex} className="col-md-4 mb-3">
                            <Card style={{ width: "100%" }}>
                              <div className="card-thumb-wrap">
                                <iframe
                                  width="100%"
                                  height="170px"
                                  src={row?.brochure || ""}
                                  title="Surgicshare Document viewer"
                                  frameBorder="0"
                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                  allowFullScreen
                                ></iframe>
                              </div>

                              <Card.Body>
                                <Card.Title>
                                  {row?.button || "No Title"}
                                </Card.Title>
                                <Button
                                  variant="primary"
                                  onClick={() =>
                                    handleOpenPdf(row?.brochure || "#")
                                  }
                                >
                                  Brochure
                                </Button>
                                <Button
                                  variant="primary"
                                  className="ms-2"
                                  onClick={() =>
                                    watchConference(
                                      row?.url || "#",
                                      row?.button || "Unknown"
                                    )
                                  }
                                >
                                  Watch Now
                                </Button>
                              </Card.Body>
                            </Card>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <NoDataFound />
                    )}
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          ) : (
            <NoDataFound />
          )}
        </Row>
      </ContainerWrapper>
    </Root>
  );
};

export default BrowseSurgeryVideo;

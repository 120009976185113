import React, { useEffect, useLayoutEffect, useState } from "react";
import Root from "../../components/Root";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import ContainerWrapper from "../../layouts/ContainerWrapper";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfileInfo,
  makePayment,
  updatePersonalProfile,
  updatePublicProfile,
  updateSchedule,
} from "../../../redux/actions/patientReferral.action";
import {
  getCities,
  getCountries,
  getDesignation,
  getFaculty,
  getInstitute,
  getQualification,
  getSpeciality,
  getStates,
  getSubSpeciality,
  getYearOfEperience,
} from "../../../redux/actions/filter.action";
import { Button } from "react-bootstrap";
import { CLEAR_PATIENT_DATA } from "../../../redux/constants/patientReferralActionTypes";
import { SET_USER_DATA } from "../../../redux/constants/authActionTypes";
import { toast } from "react-toastify";
import { changePassword } from "../../../redux/actions/auth.action";
import { object } from "yup";
import { Link, useLocation } from "react-router-dom";
import ProfileComplete from "../../components/CompleteProfile/ProfileComplete";

const CreatePatientProfile = () => {
  const location = useLocation();
  const isCompleteProfile = useSelector(
    (state) => state.globalState.isCompleteProfile
  );
  const [optionList, setOptionList] = useState([]);
  const [arrayData, setArrayData] = useState([]);
  const [show, setShow] = useState(false);
  // const [seletedTab, setSelectedTab] = useState(
  //   isCompleteProfile ? "createRefer" : "publicProfile"
  // );

  // const [seletedTab, setSelectedTab] = useState(
  //   location.pathname === "/createPatientProfile" ? "createRefer" : "publicProfile"
  // );
  const [seletedTab, setSelectedTab] = useState("publicProfile");

  const dispatch = useDispatch();
  const daysOfWeek = [
    "MONDAY",
    "TUESDAY",
    "WEDNESDAY",
    "THURSDAY",
    "FRIDAY",
    "SATURDAY",
    "SUNDAY",
  ];
  const patientReferralProfile = useSelector(
    (state) => state.patientReferral.patientReferralProfile
  );

  const userProfile = useSelector((state) => state.auth.userProfile);
  const specialityList = useSelector((state) => state.filters.speciality);
  const subSpecialityList = useSelector((state) => state.filters.subSpeciality);
  const countryList = useSelector((state) => state.filters.countries);
  const stateList = useSelector((state) => state.filters.states);
  const cityList = useSelector((state) => state.filters.cities);
  const instituteList = useSelector((state) => state.filters.institutes);
  const designationList = useSelector((state) => state.filters.designation);
  const qualificationList = useSelector((state) => state.filters.qualification);
  const experienceList = useSelector((state) => state.filters.yearOfExperience);

  const facultyList = useSelector((state) => state.filters.faculties);

  const [publicProfileFormData, setPublicProfileFormData] = useState({});
  const [passwordForm, setPasswordForm] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });
  const [userProfileOptions, setUserProfileOptions] = useState({
    subSpecialityList: [],
    stateList: [],
    cityList: [],
    institutionList: [],
    designationList: [],
  });
  const [followSubSpeciality, setFollowSubSpeciality] = useState({});
  const [deleteEmail, setDeleteEmail] = useState("");

  const [otherInstitute, setOtherInstitute] = useState(false);

  const [firstNameError, setFirstNameError] = useState(false);
  const [specialityError, setSpecialityError] = useState(false);
  const [designationError, setDesignationError] = useState(false);
  const [experienceError, setExperienceError] = useState(false);
  const [qualificationError, setQualificationError] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [institutionError, setInstitutionError] = useState(false);

  useEffect(() => {
    // if (location.pathname === "/createPatientProfile") {
    //   setSelectedTab("createRefer")
    // } else {
    //   setSelectedTab("publicProfile")
    // }
    setSelectedTab("publicProfile");
  }, [location.pathname]);

  useEffect(() => {
    dispatch(getSpeciality());
    dispatch(getProfileInfo());
    dispatch(getCountries());
    dispatch(getDesignation());
    dispatch(getQualification());
    dispatch(getYearOfEperience());
  }, []);

  useEffect(async () => {
    if (Object.keys(userProfile).length > 0) {
      let arrObj = {};
      setPublicProfileFormData({
        firstName: userProfile?.firstName ? userProfile?.firstName : null,
        lastName: userProfile?.lastName,
        speciality: userProfile?.speciality?._id
          ? userProfile?.speciality?._id
          : null,
        subSpeciality: userProfile?.subSpeciality,
        designation: userProfile?.designation?._id
          ? userProfile?.designation?._id
          : null,
        yearsOfExperience: userProfile?.yearsOfExperience?._id
          ? userProfile?.yearsOfExperience?._id
          : null,
        qualification: userProfile?.qualification
          ? userProfile?.qualification
          : null,
        other_qualification: userProfile?.other_qualification,
        other_institution: userProfile?.other_institution,
        country: userProfile?.country?._id ? userProfile?.country?._id : null,
        state: userProfile?.state ? userProfile?.state : null,
        city: userProfile?.city ? userProfile?.city : null,
        institution: userProfile?.institution?._id
          ? userProfile?.institution?._id
          : null,
        // areaOfIntrest: userProfile?.areaOfIntrest,
        // educationDetails: userProfile?.educationDetails,
        // workExperienceDetails: userProfile?.workExperienceDetails,
        // achievements: userProfile?.achievements,
        profile: "",
        onlineClassFees: userProfile?.onlineClassFees,
        followSubSpeciality: userProfile?.followSubSpeciality,
        telementorshipFees: userProfile?.telementorshipFees,
        mobile: userProfile?.mobile,
        email: userProfile?.email,
      });

      await dispatch(
        getSubSpeciality({ speciality_id: userProfile.speciality?._id })
      ).then((res) => {
        // setProfileOptionData("subSpecialityList",res)
        arrObj = {
          ...arrObj,
          subSpecialityList: res,
        };
      });
      await dispatch(getStates({ country_id: userProfile.country?._id })).then(
        (res) => {
          // setProfileOptionData("stateList",res)
          arrObj = {
            ...arrObj,
            stateList: res,
          };
        }
      );
      await dispatch(getCities({ state_id: userProfile.state })).then((res) => {
        // setProfileOptionData("cityList",res)
        arrObj = {
          ...arrObj,
          cityList: res,
        };
      });
      await dispatch(getInstitute({ city_id: userProfile.city })).then(
        (res) => {
          // setProfileOptionData("institutionList",res)
          arrObj = {
            ...arrObj,
            institutionList: res,
          };
        }
      );
      setUserProfileOptions(arrObj);
    }
  }, [userProfile]);

  useEffect(() => {
    if (patientReferralProfile.schedules) {
      mapResponse();
    }
  }, [patientReferralProfile]);

  useLayoutEffect(() => {
    return () => {
      setArrayData([]);
      setOptionList([]);
      dispatch({ type: CLEAR_PATIENT_DATA });
    };
  }, []);

  const fetchFields = async (countryId, StateId, CityId) => {
    let newData = {};
    await dispatch(getStates({ country_id: countryId })).then((res) => {
      newData = {
        ...newData,
        states: res,
      };
    });
    await dispatch(getCities({ state_id: StateId })).then((res) => {
      newData = {
        ...newData,
        cities: res,
      };
    });
    await dispatch(getInstitute({ city_id: CityId })).then((res) => {
      newData = {
        ...newData,
        institues: res,
      };
    });
    let dataArray = optionList;
    dataArray.push(newData);
    setOptionList(dataArray);
    if (arrayData.length === optionList.length) {
      setShow(true);
    }
  };
  const makePayments = () => {
    dispatch(makePayment());
  };

  const mapResponse = () => {
    patientReferralProfile.schedules.map((schedule, index) => {
      schedule.schedules.map((val) => {
        fetchFields(val.country, val.state, val.city);
        let newArr = arrayData;
        newArr.push(val);
        setArrayData(newArr);
      });
    });
  };
  const handleAddAnotherInstitute = () => {
    const newDataArray = [...arrayData];
    const newOptionArray = [...optionList];

    newDataArray.push({
      day: "",
      available_from: "",
      available_to: "",
      country: "",
      state: "",
      city: "",
      instituteName: "",
    });

    newOptionArray.push({
      states: [],
      cities: [],
      institues: [],
    });

    setOptionList(newOptionArray);
    setArrayData(newDataArray);
  };

  const handleFormDataChange = async (e) => {
    const { name, value } = e.target;
    setPublicProfileFormData({ ...publicProfileFormData, [name]: value });
    if (name === "speciality") {
      await dispatch(getSubSpeciality({ speciality_id: value })).then((res) => {
        setUserProfileOptions({
          ...userProfileOptions,
          subSpecialityList: res,
        });
      });
    } else if (name === "country") {
      await dispatch(getStates({ country_id: value })).then((res) => {
        setUserProfileOptions({ ...userProfileOptions, stateList: res });
      });
      setOtherInstitute(false);
    } else if (name === "state") {
      await dispatch(getCities({ state_id: value })).then((res) => {
        setUserProfileOptions({ ...userProfileOptions, cityList: res });
      });
      setOtherInstitute(false);
    } else if (name === "city") {
      await dispatch(getInstitute({ city_id: value })).then((res) => {
        setUserProfileOptions({ ...userProfileOptions, institutionList: res });
      });
      setOtherInstitute(false);
    } else if (name === "institution") {
      const selectedInstitution = userProfileOptions?.institutionList.find(
        (option) => option?._id === value
      );

      if (selectedInstitution.instituteName == "Other Institute") {
        setOtherInstitute(true);
      } else {
        setOtherInstitute(false);
      }
    }
  };

  const handlePasswordFormChange = (e) => {
    setPasswordForm({ ...passwordForm, [e.target.name]: e.target.value });
  };
  const handleDeleteAccount = (e) => {
    setDeleteEmail(e.target.value);
  };
  const submitDeleteAccount = () => {
    if (deleteEmail == "") {
      toast.error("Email should not be blank", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    toast.success("Account Deleted Successfully.", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    setDeleteEmail("");
  };

  const handlePasswordSubmit = () => {
    if (
      passwordForm.password &&
      passwordForm.confirmPassword &&
      passwordForm.oldPassword
    ) {
      if (passwordForm.password !== passwordForm.confirmPassword) {
        toast.error("Passwords do not match.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        //api call
        dispatch(changePassword(passwordForm));
        setPasswordForm({
          oldPassword: "",
          password: "",
          confirmPassword: "",
        });
      }
    } else {
      toast.error("Please enter all the fields", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  const handlePublicProfileSubmit = async () => {
    console.log(publicProfileFormData);

    const formData = new FormData();

    formData.append("firstName", publicProfileFormData.firstName);
    formData.append("lastName", publicProfileFormData.lastName);
    formData.append("speciality", publicProfileFormData.speciality);
    console.log("Sub Speciality value", publicProfileFormData.subSpeciality);
    if (
      publicProfileFormData.subSpeciality != "" &&
      publicProfileFormData.subSpeciality != null &&
      publicProfileFormData.subSpeciality != "Select" &&
      publicProfileFormData.subSpeciality != undefined
    ) {
      formData.append("subSpeciality", publicProfileFormData.subSpeciality);
    }

    formData.append("designation", publicProfileFormData.designation);
    formData.append(
      "yearsOfExperience",
      publicProfileFormData.yearsOfExperience
    );

    formData.append("qualification", publicProfileFormData.qualification);
    formData.append("country", publicProfileFormData.country);
    formData.append("state", publicProfileFormData.state);
    formData.append("city", publicProfileFormData.city);
    formData.append("institution", publicProfileFormData.institution);
    // formData.append("areaOfIntrest", publicProfileFormData.areaOfIntrest);
    // formData.append("educationDetails", publicProfileFormData.educationDetails);
    // formData.append(
    //   "workExperienceDetails",
    //   publicProfileFormData.workExperienceDetails
    // );
    // formData.append("achievements", publicProfileFormData.achievements);
    if (publicProfileFormData.institution === "65167b11dcd3758679825ac0") {
      formData.append(
        "other_institution",
        publicProfileFormData.other_institution
      );
    }
    if (publicProfileFormData.qualification === "64c2105519ae4f31200bfd32") {
      formData.append(
        "other_qualification",
        publicProfileFormData.other_qualification
      );
    }

    if (publicProfileFormData.profile) {
      formData.append("profilePicture", publicProfileFormData.profile);
    }
    dispatch(updatePublicProfile(formData, publicProfileFormData));
  };

  const handlePersonalProfileSubmit = () => {
    // const formData = new FormData();
    // formData.append("email", publicProfileFormData.email);
    // formData.append("mobile", publicProfileFormData.mobile);
    // formData.append("telementorshipFees", publicProfileFormData.telementorshipFees);
    // formData.append("onlineClassFees", publicProfileFormData.onlineClassFees);
    // formData.append("followSubSpeciality", publicProfileFormData.followSubSpeciality);
    let requestData = {
      email: publicProfileFormData.email,
      mobile: publicProfileFormData.mobile,
      telementorshipFees: publicProfileFormData.telementorshipFees,
      onlineClassFees: publicProfileFormData.onlineClassFees,
      followSubSpeciality: JSON.stringify(
        publicProfileFormData.followSubSpeciality.map((item) => item?._id)
      ),
    };
    dispatch(updatePersonalProfile(requestData, publicProfileFormData));
  };
  console.log(
    "publicProfileFormData.followSubSpeciality",
    publicProfileFormData.followSubSpeciality
  );
  const handleFollowSubspeciality = () => {
    let data = publicProfileFormData.followSubSpeciality;
    data.push(followSubSpeciality);
    setPublicProfileFormData({
      ...publicProfileFormData,
      followSubSpeciality: data,
    });
    setFollowSubSpeciality({});
  };

  const handleFollowSubSpecialityChange = (event) => {
    const selectedOption = event.target.value;
    const selectedOptionLabel = event.target[event.target.selectedIndex].text;
    setFollowSubSpeciality({
      _id: selectedOption,
      subSpecialityName: selectedOptionLabel,
    });
  };

  const handleRemoveFollow = (val) => {
    const newArray = publicProfileFormData.followSubSpeciality.filter(
      (item) => item?._id !== val?._id
    );
    setPublicProfileFormData({
      ...publicProfileFormData,
      followSubSpeciality: newArray,
    });
  };

  const handleProfileImageChange = (event) => {
    const file = event.target.files[0];
    setPublicProfileFormData((prevForm) => ({
      ...prevForm,
      profile: file, // Update the profile with the selected file
    }));
  };

  const renderData = (tab) => {
    switch (tab) {
      case "createRefer":
        // return (
        //   <>
        //     <ProfileComplete />
        //     <div className="card-body pt-1 px-4 py-4">
        //       {patientReferralProfile.subscription_status === true ? (
        //         <>
        //           <div className="card-header bg-white border-0 px-4 pt-4 pb-0">
        //             <div className="col-12 ">
        //               <div>
        //                 <h2 className="h4 mb-1">Patient Referral Profile</h2>
        //                 <p>
        //                   Fields with <span className="text-danger">*</span> are
        //                   mandatory.
        //                 </p>
        //               </div>
        //             </div>
        //           </div>
        //           <form className="row g-3 g-lg-4">
        //             {show &&
        //               arrayData.map((val, index) => (
        //                 <>
        //                   <div className="col-md-4">
        //                     <label for="" className="form-label">
        //                       Select Day
        //                       <span className="text-danger">*</span>
        //                     </label>
        //                     <select
        //                       value={val.day}
        //                       id=""
        //                       className="form-select"
        //                       onChange={(e) =>
        //                         handleChange(index, "day", e.target.value)
        //                       }
        //                     >
        //                       <option selected>Select</option>
        //                       {daysOfWeek.map((day) => (
        //                         <option key={day} value={day}>
        //                           {day}
        //                         </option>
        //                       ))}
        //                     </select>
        //                   </div>
        //                   <div className="col-md-4">
        //                     <label for="" className="form-label">
        //                       Available From
        //                       <span className="text-danger">*</span>
        //                     </label>
        //                     <input
        //                       type="time"
        //                       className="form-control"
        //                       id=""
        //                       placeholder=""
        //                       value={val.available_from}
        //                       onChange={(e) =>
        //                         handleChange(
        //                           index,
        //                           "available_from",
        //                           e.target.value
        //                         )
        //                       }
        //                     />
        //                   </div>
        //                   <div className="col-md-4">
        //                     <label for="" className="form-label">
        //                       Available To
        //                       <span className="text-danger">*</span>
        //                     </label>
        //                     <input
        //                       type="time"
        //                       className="form-control"
        //                       id=""
        //                       placeholder=" "
        //                       value={val.available_to}
        //                       onChange={(e) =>
        //                         handleChange(
        //                           index,
        //                           "available_to",
        //                           e.target.value
        //                         )
        //                       }
        //                     />
        //                   </div>
        //                   <div className="col-md-4">
        //                     <label for="" className="form-label">
        //                       Country
        //                       <span className="text-danger">*</span>
        //                     </label>
        //                     <select
        //                       value={val.country}
        //                       id=""
        //                       className="form-select"
        //                       onChange={(e) =>
        //                         handleChange(index, "country", e.target.value)
        //                       }
        //                     >
        //                       <option selected>Select</option>
        //                       {countryList.map((val, index) => (
        //                         <option key={index} value={val?._id}>
        //                           {val.countryName}
        //                         </option>
        //                       ))}
        //                     </select>
        //                   </div>
        //                   <div className="col-md-4">
        //                     <label for="" className="form-label">
        //                       State<span className="text-danger">*</span>
        //                     </label>
        //                     <select
        //                       value={val.state}
        //                       id=""
        //                       className="form-select"
        //                       onChange={(e) =>
        //                         handleChange(index, "state", e.target.value)
        //                       }
        //                     >
        //                       <option selected>Select</option>

        //                       {optionList.length &&
        //                         optionList[index]?.states.map(
        //                           (value, optionIndex) => (
        //                             <option key={optionIndex} value={value?._id}>
        //                               {value.stateName}
        //                             </option>
        //                           )
        //                         )}
        //                     </select>
        //                   </div>
        //                   <div className="col-md-4">
        //                     <label for="" className="form-label">
        //                       City<span className="text-danger">*</span>
        //                     </label>
        //                     <select
        //                       value={val.city}
        //                       id=""
        //                       className="form-select"
        //                       onChange={(e) =>
        //                         handleChange(index, "city", e.target.value)
        //                       }
        //                     >
        //                       <option selected>Select</option>
        //                       {optionList.length &&
        //                         optionList[index]?.cities.map(
        //                           (value, optionIndex) => (
        //                             <option key={optionIndex} value={value?._id}>
        //                               {value.cityName}
        //                             </option>
        //                           )
        //                         )}
        //                     </select>
        //                   </div>
        //                   <div className="col-md-12">
        //                     <label for="" className="form-label">
        //                       Hospital/College/Institute Name
        //                       <span className="text-danger">*</span>
        //                     </label>
        //                     <select
        //                       value={val.institution}
        //                       id=""
        //                       className="form-select"
        //                       onChange={(e) =>
        //                         handleChange(index, "institution", e.target.value)
        //                       }
        //                     >
        //                       <option selected>Select</option>
        //                       {optionList.length &&
        //                         optionList[index]?.institues.map(
        //                           (value, optionIndex) => (
        //                             <option key={optionIndex} value={value?._id}>
        //                               {value.instituteName}
        //                             </option>
        //                           )
        //                         )}
        //                     </select>
        //                   </div>
        //                 </>
        //               ))}

        //             <div className="col-12 text-start mt-3">
        //               <button
        //                 type="button"
        //                 className="btn btn-light mb-2 btn-lg"
        //                 onClick={() => handleAddAnotherInstitute()}
        //               >
        //                 <i className="fa-solid fa-plus me-1"></i> Add Another
        //                 Institute
        //               </button>
        //             </div>
        //             <div className="col-12 text-center">
        //               <button
        //                 type="button"
        //                 className="btn btn-primary mb-2 btn-lg"
        //                 onClick={() => handleUpdate()}
        //               >
        //                 <i className="fa-solid fa-check me-1"></i> Update Schedule
        //               </button>
        //             </div>
        //           </form>
        //         </>
        //       ) : (
        //         <div className="bg-primary p-4 text-center rounded-3">
        //           <h4 className="text-white">Create Patient Referral Profile</h4>
        //           <p className="text-light ">
        //             <strong>LAUNCH OFFER:</strong> Create your patient referral
        //             profile for <strong>Rs 2,490/-</strong>{" "}
        //             <em>(Inclusive of GST)</em> and get 1 year of patient
        //             referrals.
        //           </p>
        //           <p className="text-light">
        //             Kindly{" "}
        //             {/* <Link
        //               // href="my-profile.html"
        //               onClick={renderData("publicProfile")}
        //               className="text-white text-decoration-underline"
        //             >
        //               click here
        //             </Link>{" "} */}
        //             update your public profile before creating referral
        //             profile.
        //           </p>
        //           <Button
        //             type="button"
        //             onClick={() => makePayments()}
        //             className="btn btn-success mb-2 btn-lg"
        //           >
        //             Proceed to Payment{" "}
        //             <i className="fa-solid fa-arrow-right me-1"></i>
        //           </Button>
        //         </div>
        //         // <div className="bg-primary p-4 text-center rounded-3">
        //         //  <h4 style={{color: '#fff'}}>This service will be availble soon...</h4>
        //         // </div>
        //       )}
        //     </div>
        //   </>
        // );

        break;

      case "ChangePassword":
        return (
          <>
            <div class="card-header bg-white border-0 px-4 pt-4 pb-0">
              <div class="col-12 ">
                <div>
                  <h2 class="h4 mb-1">Change Password</h2>
                  <p>
                    Enter your old and new password to change your password.
                    Fields with <span class="text-danger">*</span> are
                    mandatory.
                  </p>
                </div>
              </div>
            </div>
            <div class="card-body pt-1 px-4 py-4">
              <form class="row g-3 g-lg-4">
                <div class="col-md-12">
                  <label for="" class="form-label">
                    Old Password<span class="text-danger">*</span>
                  </label>
                  <input
                    type="password"
                    class="form-control"
                    id=""
                    placeholder=" "
                    name="oldPassword"
                    value={passwordForm.oldPassword}
                    onChange={(e) => handlePasswordFormChange(e)}
                  />
                </div>
                <div class="col-md-6">
                  <label for="" class="form-label">
                    New Password<span class="text-danger">*</span>
                  </label>
                  <input
                    type="password"
                    class="form-control"
                    id=""
                    placeholder=" "
                    name="password"
                    value={passwordForm.password}
                    onChange={(e) => handlePasswordFormChange(e)}
                  />
                </div>
                <div class="col-md-6">
                  <label for="" class="form-label">
                    Re-Enter New Password<span class="text-danger">*</span>
                  </label>
                  <input
                    type="password"
                    class="form-control"
                    id=""
                    placeholder=" "
                    name="confirmPassword"
                    value={passwordForm.confirmPassword}
                    onChange={(e) => handlePasswordFormChange(e)}
                  />
                </div>
                <div class="col-12 text-center">
                  <button
                    type="button"
                    onClick={() => handlePasswordSubmit()}
                    class="btn btn-primary mb-2 btn-lg"
                  >
                    <i class="fa-solid fa-check me-1"></i>
                    Change Password
                  </button>
                </div>
              </form>
            </div>
          </>
        );
      case "DeleteAccount":
        return (
          <>
            <div class="card-header bg-white border-0 px-4 pt-4 pb-0">
              <div class="col-12 ">
                <div>
                  <h2 class="h4 mb-1">DeleteAccount</h2>
                  <p>
                    Enter your Email Id to delete account. Fields with{" "}
                    <span class="text-danger">*</span> are mandatory.
                  </p>
                </div>
              </div>
            </div>
            <div class="card-body pt-1 px-4 py-4">
              <form class="row g-3 g-lg-4">
                <div class="col-md-12">
                  <label for="" class="form-label">
                    Email Id<span class="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    class="form-control"
                    id=""
                    placeholder=" "
                    name="deleteEmail"
                    value={deleteEmail}
                    onChange={(e) => handleDeleteAccount(e)}
                  />
                </div>

                <div class="col-12 text-center">
                  <button
                    type="button"
                    onClick={() => submitDeleteAccount()}
                    class="btn btn-primary mb-2 btn-lg"
                  >
                    <i class="fa-solid fa-check me-1"></i>
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </>
        );
      case "personalInfo":
        return (
          <>
            <div className="card-header bg-white border-0 px-4 pt-4 pb-0">
              <div className="col-12 ">
                <div>
                  <h2 className="h4 mb-1">Personal Information</h2>
                  <p>
                    Update your personal information. Fields with{" "}
                    <span className="text-danger">*</span> are mandatory.
                  </p>
                </div>
              </div>
            </div>
            <div className="card-body pt-1 px-4 py-4">
              <form className="row g-3 g-lg-4">
                <div className="col-md-6">
                  <label for="" className="form-label">
                    Mobile<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id=""
                    placeholder=" "
                    value={publicProfileFormData.mobile}
                    name="mobile"
                    onChange={(e) => handleFormDataChange(e)}
                    disabled={true}
                  />
                </div>
                <div className="col-md-6">
                  <label for="" className="form-label">
                    Email ID<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id=""
                    placeholder=" "
                    value={publicProfileFormData.email}
                    name="email"
                    onChange={(e) => handleFormDataChange(e)}
                    disabled={true}
                  />
                </div>
                {/* <div className="col-md-6">
                  <label for="" className="form-label">
                    Telementorship Fees<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      id=""
                      placeholder=" "
                      value={publicProfileFormData.telementorshipFees}
                      name="telementorshipFees"
                      onChange={(e) => handleFormDataChange(e)}
                    />
                    <span className="input-group-text">per hour</span>
                    <span className="input-group-text fw-bold">+ 18% GST</span>
                  </div>
                </div>
                <div className="col-md-6">
                  <label for="" className="form-label">
                    Online Class Fees<span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      id=""
                      placeholder=" "
                      value={publicProfileFormData.onlineClassFees}
                      name="onlineClassFees"
                      onChange={(e) => handleFormDataChange(e)}
                    />
                    <span className="input-group-text">per hour</span>
                    <span className="input-group-text fw-bold">+ 18% GST</span>
                  </div>
                </div> */}
                <div className="col-md-12">
                  <label for="" className="form-label">
                    Follow Subspeciality<span className="text-danger">*</span>
                  </label>
                  <div className="input-group d-flex align-items-stretch">
                    <select
                      value={followSubSpeciality?._id}
                      name="subSpeciality"
                      id=""
                      className="form-select"
                      onChange={(e) => handleFollowSubSpecialityChange(e)}
                    >
                      <option selected="">Select</option>
                      {userProfileOptions?.subSpecialityList?.map(
                        (val, index) => (
                          <option key={index} value={val?._id}>
                            {val.subSpecialityName}
                          </option>
                        )
                      )}
                    </select>
                    <div className="input-group-btn d-flex align-items-stretch">
                      <button
                        disabled={
                          Object.keys(followSubSpeciality)?.length === 0
                        }
                        onClick={() => handleFollowSubspeciality()}
                        type="button"
                        className="btn btn-primary"
                      >
                        <i className="fa-solid fa-check me-1"></i> Follow
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <label for="" className="form-label d-block">
                    You are following:
                  </label>
                  {publicProfileFormData.followSubSpeciality?.length > 0 ? (
                    publicProfileFormData.followSubSpeciality?.map((val) => (
                      <label className="badge bg-light badge-lg font-weight-bolder text-primary mb-2 me-2 p-2 rounde-3">
                        {val.subSpecialityName}
                        <span
                          onClick={() => handleRemoveFollow(val)}
                          className="text-danger fs-6"
                        >
                          <i className="fa-solid fa-circle-xmark"></i>
                        </span>
                      </label>
                    ))
                  ) : (
                    <label>You are not following any Subspeciality</label>
                  )}
                </div>
                <div className="col-12 text-center">
                  <button
                    onClick={() => handlePersonalProfileSubmit()}
                    type="button"
                    className="btn btn-primary mb-2 btn-lg"
                  >
                    <i className="fa-solid fa-check me-1"></i>
                    Update Personal Profile
                  </button>
                </div>
              </form>
            </div>
          </>
        );

      case "publicProfile":
        return (
          <>
            <div className="card-header bg-white border-0 px-4 pt-4 pb-0">
              <div className="col-12 ">
                <div>
                  <h2 className="h4 mb-1">Public Profile</h2>
                  <p>
                    Update your public profile. Fields with{" "}
                    <span className="text-danger">*</span> are mandatory.
                  </p>
                </div>
              </div>
            </div>

            <div className="card-body pt-1 px-4 py-4">
              <form className="row g-3 g-lg-4">
                <div className="col-md-12">
                  <label for="" className="form-label">
                    Profile Picture<span className="text-danger">*</span>
                  </label>
                  <div className="d-md-flex align-items-md-center">
                    <div
                      id="imagePreview"
                      className="preview rounded-3 mb-2 me-3"
                    ></div>
                    <button
                      type="button"
                      className="btn btn-light profile-upload-btn"
                    >
                      <i className="fa-solid fa-upload me-1"></i> Upload Profile
                      Picture{" "}
                      <input
                        type="file"
                        className="form-control-file"
                        id="imageUpload"
                        name="imageUpload"
                        accept="image/*"
                        onChange={handleProfileImageChange}
                      />
                    </button>
                  </div>
                </div>
                <div className="col-md-6">
                  <label for="" className="form-label">
                    Full Name<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      firstNameError ? "border border-danger" : ""
                    }`}
                    id=""
                    placeholder=" "
                    name="firstName"
                    onChange={(e) => handleFormDataChange(e)}
                    value={publicProfileFormData.firstName}
                  />
                  {firstNameError && (
                    <p className="text-danger">FirstName is required</p>
                  )}
                </div>

                {/* <div className="col-md-6">
                  <label for="" className="form-label">
                    Last Name<span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id=""
                    placeholder=" "
                    name="lastName"
                    onChange={(e) => handleFormDataChange(e)}
                    value={publicProfileFormData.lastName}
                  />
                </div> */}

                <div className="col-md-6">
                  <label for="" className="form-label">
                    Speciality<span className="text-danger">*</span>
                  </label>
                  <select
                    name="speciality"
                    onChange={(e) => handleFormDataChange(e)}
                    id=""
                    className={`form-select ${
                      specialityError ? "border border-danger" : ""
                    }`}
                    value={publicProfileFormData.speciality}
                  >
                    <option value="" selected>
                      Select
                    </option>
                    {specialityList.map((val, index) => (
                      <option key={index} value={val?._id}>
                        {val.specialityName}
                      </option>
                    ))}
                  </select>
                  {specialityError && (
                    <p className="text-danger">Speciality is required</p>
                  )}
                </div>

                <div className="col-md-6">
                  <label for="" className="form-label">
                    Sub Speciality
                  </label>
                  <select
                    name="subSpeciality"
                    onChange={(e) => handleFormDataChange(e)}
                    id=""
                    className="form-select"
                    value={publicProfileFormData.subSpeciality}
                  >
                    <option value="" selected>
                      Select
                    </option>
                    {userProfileOptions?.subSpecialityList?.map(
                      (val, index) => (
                        <option key={index} value={val?._id}>
                          {val.subSpecialityName}
                        </option>
                      )
                    )}
                  </select>
                </div>
                <div className="col-md-6">
                  <label for="" className="form-label">
                    Designation<span className="text-danger">*</span>
                  </label>
                  <select
                    onChange={(e) => handleFormDataChange(e)}
                    value={publicProfileFormData.designation}
                    id=""
                    className={`form-select ${
                      designationError ? "border border-danger" : ""
                    }`}
                    name="designation"
                  >
                    <option value="" selected>
                      Select
                    </option>
                    {designationList.map((val, index) => (
                      <option key={index} value={val?._id}>
                        {val.designationName}
                      </option>
                    ))}
                  </select>
                  {designationError && (
                    <p className="text-danger">Designation is required</p>
                  )}
                </div>

                <div className="col-md-6">
                  <label for="" className="form-label">
                    Years of experience<span className="text-danger">*</span>
                  </label>
                  <select
                    value={publicProfileFormData.yearsOfExperience}
                    id=""
                    className={`form-select ${
                      experienceError ? "border border-danger" : ""
                    }`}
                    onChange={(e) => handleFormDataChange(e)}
                    name={"yearsOfExperience"}
                  >
                    <option value="" selected>
                      Select
                    </option>
                    {experienceList.map((val, index) => (
                      <option key={index} value={val?._id}>
                        {val.yearsOfExperience}
                      </option>
                    ))}
                  </select>
                  {experienceError && (
                    <p className="text-danger">Experience is required</p>
                  )}
                </div>

                <div className="col-md-6">
                  <label for="" className="form-label">
                    Country<span className="text-danger">*</span>
                  </label>
                  <select
                    value={publicProfileFormData.country}
                    name="country"
                    onChange={(e) => handleFormDataChange(e)}
                    id=""
                    className={`form-select ${
                      countryError ? "border border-danger" : ""
                    }`}
                  >
                    <option value="" selected>
                      Select
                    </option>
                    {countryList.map((val, index) => (
                      <option key={index} value={val?._id}>
                        {val.countryName}
                      </option>
                    ))}
                  </select>
                  {countryError && (
                    <p className="text-danger">Country is required</p>
                  )}
                </div>

                <div className="col-md-6">
                  <label for="" className="form-label">
                    State<span className="text-danger">*</span>
                  </label>
                  <select
                    value={publicProfileFormData.state}
                    name="state"
                    onChange={(e) => handleFormDataChange(e)}
                    id=""
                    className={`form-select ${
                      stateError ? "border border-danger" : ""
                    }`}
                  >
                    <option value="" selected>
                      Select
                    </option>
                    {userProfileOptions?.stateList?.map((val, index) => (
                      <option key={index} value={val?._id}>
                        {val.stateName}
                      </option>
                    ))}
                  </select>
                  {stateError && (
                    <p className="text-danger">State is required</p>
                  )}
                </div>

                <div className="col-md-6">
                  <label for="" className="form-label">
                    City<span className="text-danger">*</span>
                  </label>
                  <select
                    value={publicProfileFormData.city}
                    name="city"
                    onChange={(e) => handleFormDataChange(e)}
                    id=""
                    className={`form-select ${
                      cityError ? "border border-danger" : ""
                    }`}
                  >
                    <option value="" selected>
                      Select
                    </option>
                    {userProfileOptions?.cityList?.map((val, index) => (
                      <option key={index} value={val?._id}>
                        {val.cityName}
                      </option>
                    ))}
                  </select>
                  {cityError && <p className="text-danger">City is required</p>}
                </div>

                <div className="col-md-12">
                  <label for="" className="form-label">
                    Hospital/College/Institute Name
                    <span className="text-danger">*</span>
                  </label>
                  <select
                    value={publicProfileFormData.institution}
                    name="institution"
                    onChange={(e) => handleFormDataChange(e)}
                    id=""
                    className={`form-select ${
                      institutionError ? "border border-danger" : ""
                    }`}
                  >
                    <option value="" selected>
                      Select
                    </option>
                    {userProfileOptions?.institutionList?.map((val, index) => (
                      <option key={index} value={val?._id}>
                        {val.instituteName}
                      </option>
                    ))}
                  </select>
                  {institutionError && (
                    <p className="text-danger">Institution is required</p>
                  )}
                </div>
                {/* {otherInstitute ? ( */}
                {publicProfileFormData.institution ===
                  "65167b11dcd3758679825ac4" && (
                  <div className="col-md-12">
                    <label for="" className="form-label">
                      Institution Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id=""
                      placeholder="Enter your institution"
                      name="other_institution"
                      onChange={(e) => handleFormDataChange(e)}
                      value={publicProfileFormData.other_institution}
                    />
                  </div>
                )}

                <div className="col-md-12">
                  <label for="" className="form-label">
                    Highest Qualification <span className="text-danger">*</span>
                  </label>
                  <select
                    onChange={(e) => handleFormDataChange(e)}
                    value={publicProfileFormData.qualification}
                    id=""
                    name="qualification"
                    className={`form-select ${
                      qualificationError ? "border border-danger" : ""
                    }`}
                  >
                    <option value="" selected>
                      Select
                    </option>
                    {qualificationList.map((val, index) => (
                      <option key={index} value={val?._id}>
                        {val.qualificationName}
                      </option>
                    ))}
                  </select>
                  {qualificationError && (
                    <p className="text-danger">Qualification is required</p>
                  )}
                </div>

                {/* {publicProfileFormData.qualification ===
                  "64c2105519ae4f31200bfd32" && ( */}
                <div className="col-md-12">
                  <label for="" className="form-label">
                    Full Qualification
                    {/* <span className="text-danger">*</span> */}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id=""
                    placeholder="Enter your qualification"
                    name="other_qualification"
                    onChange={(e) => handleFormDataChange(e)}
                    value={publicProfileFormData.other_qualification}
                  />
                </div>
                {/* )} */}

                {/* <div className="col-md-12">
                  <label for="" className="form-label">
                    Area of Intrest
                  </label>
                  <textarea
                    value={publicProfileFormData.areaOfIntrest}
                    name="areaOfIntrest"
                    type="text"
                    className="form-control"
                    id=""
                    placeholder=" "
                    rows="4"
                    onChange={(e) => handleFormDataChange(e)}
                  ></textarea>
                </div>
                <div className="col-md-12">
                  <label for="" className="form-label">
                    Education Details
                  </label>
                  <textarea
                    value={publicProfileFormData.educationDetails}
                    name="educationDetails"
                    type="text"
                    className="form-control"
                    id=""
                    placeholder=" "
                    rows="4"
                    onChange={(e) => handleFormDataChange(e)}
                  ></textarea>
                </div>
                <div className="col-md-12">
                  <label for="" className="form-label">
                    Work Experience Details
                  </label>
                  <textarea
                    value={publicProfileFormData.workExperienceDetails}
                    name="workExperienceDetails"
                    type="text"
                    className="form-control"
                    id=""
                    placeholder=" "
                    rows="4"
                    onChange={(e) => handleFormDataChange(e)}
                  ></textarea>
                </div>
                <div className="col-md-12">
                  <label for="" className="form-label">
                    Achievements
                  </label>
                  <textarea
                    value={publicProfileFormData.achievements}
                    name="achievements"
                    type="text"
                    className="form-control"
                    id=""
                    placeholder=" "
                    rows="4"
                    onChange={(e) => handleFormDataChange(e)}
                  ></textarea>
                </div> */}
                <div className="col-12 text-center">
                  <button
                    onClick={() => handlePublicProfileSubmit()}
                    type="button"
                    className="btn btn-primary mb-2 btn-lg"
                  >
                    <i className="fa-solid fa-check me-1"></i>
                    Update Public Profile
                  </button>
                </div>
              </form>
            </div>
          </>
        );
      default:
        break;
    }
  };

  const handleChange = (index, field, value) => {
    const newDataArray = [...arrayData];
    newDataArray[index] = {
      ...newDataArray[index],
      [field]: value,
    };
    setArrayData(newDataArray);

    if (field === "country") {
      dispatch(getStates({ country_id: value })).then((res) => {
        const newOptionList = [...optionList];
        newOptionList[index] = {
          ...newOptionList[index],
          states: res,
        };
        setOptionList(newOptionList);
      });
    } else if (field === "state") {
      dispatch(getCities({ state_id: value })).then((res) => {
        const newOptionList = [...optionList];
        newOptionList[index] = {
          ...newOptionList[index],
          cities: res,
        };
        setOptionList(newOptionList);
      });
    } else if (field === "city") {
      dispatch(getInstitute({ city_id: value })).then((res) => {
        const newOptionList = [...optionList];
        newOptionList[index] = {
          ...newOptionList[index],
          institues: res,
        };
        setOptionList(newOptionList);
      });
    }
  };

  const handleUpdate = () => {
    dispatch(updateSchedule({ schedule: JSON.stringify(arrayData) }));
  };
  return (
    <Root>
      <Breadcrumb
        title={"My Profile"}
        items={[
          {
            name: "Dashboard",
            routeTo: "/dashboard",
          },
          {
            name: "My Profile",
          },
        ]}
      />
      <ContainerWrapper>
        <section className="py-4">
          <div className="row d-flex align-items-stretch">
            <div className="col-lg-5 col-xl-4 d-flex align-items-stretch">
              <div className="bg-white card overflow-hidden rounded-3 mb-4 overflow-hidden w-100">
                <div className="card-body px-4 py-4">
                  <div className="">
                    <div className="mb-3">
                      <div className="author d-flex align-items-center">
                        <span className="user-image-wrap user-image-lg me-md-3 mb-3 mb-md-0">
                          {userProfile.profilePicture ? (
                            <img
                              src={`https://d27m4xh8yh8co7.cloudfront.net/${process.env.REACT_APP_STAGE}${userProfile.profilePicture}`}
                              alt=""
                              title="My Profile"
                            />
                          ) : (
                            <img
                              src={require("../../../img/user.png")}
                              alt=""
                              title="My Profile"
                            />
                          )}
                        </span>
                        <span className="author-details">
                          <h2 className="h5 fw-bold mb-1">
                            Dr. {userProfile.firstName} {userProfile.lastName}
                          </h2>
                          <p className="mb-2 text-muted">
                            {userProfile?.institution?.instituteName}
                          </p>
                        </span>
                      </div>
                    </div>
                    <div className="col text-start pt-1">
                      <p className="mb-2">
                        <strong>Email:</strong> {userProfile.email}
                      </p>
                      <p className="mb-2">
                        <strong>Phone:</strong> {userProfile.mobile}
                      </p>
                      <p className="">
                        <strong>Location:</strong>{" "}
                        {userProfile?.country?.state?.city?.cityName},
                        {userProfile?.country?.state?.stateName},
                        {userProfile?.country?.countryName}
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div className="d-grid gap-2 profile-sidebar-links">
                    <a
                      href="javascript:void(0)"
                      onClick={() => setSelectedTab("publicProfile")}
                      className="btn btn-light bg-white border-0 btn-lg text-start "
                    >
                      <i className="ki-solid ki-user text-primary me-2"></i>{" "}
                      Public Profile
                    </a>
                    {/* {isCompleteProfile ? ( */}
                    <>
                      <a
                        href="javascript:void(0)"
                        onClick={() => setSelectedTab("personalInfo")}
                        className="btn btn-light bg-white border-0 btn-lg text-start "
                      >
                        <i className="ki-solid ki-user-tick text-primary me-2"></i>{" "}
                        Personal Information
                      </a>
                      {/* <a
                        href="javascript:void(0)"
                        onClick={() => setSelectedTab("createRefer")}
                        className="btn btn-light bg-white border-0 btn-lg text-start active"
                      >
                        <i className="ki-solid ki-profile-user text-primary me-2"></i>{" "}
                        Patient Referral Profile
                      </a> */}
                    </>
                    {/* ) : null} */}
                    <a
                      href="javascript:void(0)"
                      onClick={() => setSelectedTab("ChangePassword")}
                      className="btn btn-light bg-white border-0 btn-lg text-start "
                    >
                      <i className="ki-solid ki-setting-2 text-primary me-2"></i>{" "}
                      Change Password{" "}
                    </a>
                    <a
                      href="javascript:void(0)"
                      onClick={() => setSelectedTab("DeleteAccount")}
                      className="btn btn-light bg-white border-0 btn-lg text-start "
                    >
                      <i className="ki-solid ki-setting-2 text-primary me-2"></i>{" "}
                      Delete Account{" "}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-xl-8 d-flex align-items-stretch">
              <div className="bg-white card overflow-hidden rounded-3 mb-4 overflow-hidden w-100">
                {renderData(seletedTab)}
              </div>
            </div>
          </div>
        </section>
      </ContainerWrapper>
    </Root>
  );
};

export default CreatePatientProfile;
